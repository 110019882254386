import {createAsyncThunk} from '@reduxjs/toolkit';
import { API_BASE_URL } from "../../environment";
import oktaAuth from "../../oktaAuth";


// API call for get list users data
export const getListOfUsers = createAsyncThunk(
  'getListOfUsers',
  async (status = 0) => {
    const url = `${API_BASE_URL}api/v1/users/`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
          Accept: 'application/json',
          'X-Frame-Options': 'SAMEORIGIN',
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
       
        // Handle non-2xx response
        throw new Error('Request failed with status ' + response.status);
      }
      const data = await response.json();
      const updatedData = {statusCode: status, listData: data}
      return updatedData;
    } catch (error) {
      // Handle error
      console.log(error)
      throw error;
    }
  },
);

// API call for delete  user data
export const deleteUser = createAsyncThunk(
  'deleteUser',
  async (userName, {dispatch}) => {
    const url = `${API_BASE_URL}api/v1/users/${userName}`;
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
          Accept: 'application/json',
          'X-Frame-Options': 'SAMEORIGIN',
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 204) {
        dispatch(getListOfUsers(response.status))
      }
      return response.status;
    } catch (error) {
      // Handle error
      console.log(error)
      throw error;
    }
  },
);

// API call for edit user data
export const editUser = createAsyncThunk(
  'editUser',
  async ({id, updatedData}, { dispatch }) => {
    const url = `${API_BASE_URL}api/v1/users/${id}`;
    try {
      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(updatedData),
        headers: {
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
          Accept: 'application/json',
          'X-Frame-Options': 'SAMEORIGIN',
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 201) {
        dispatch(getListOfUsers(response.status))
      }
      return response.status;
    } catch (error) {
      // Handle error
      console.log(error)
      throw error;
    }
  },
);

// API call for create users data
export const createUser = createAsyncThunk(
    'createUser',
    async (formData) => {
      const url = `${API_BASE_URL}api/v1/users/adduser`;
      try {
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(formData),
          headers: {
            Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
            Accept: 'application/json',
            'X-Frame-Options': 'SAMEORIGIN',
            'Content-Type': 'application/json',
          },
        });
       // if (!response.ok) {
         
        //   // Handle non-2xx response
        //   throw new Error('Request failed with status ' + response.status);
        // }
        const data = await response.json();
        return data;
      } catch (error) {
        // Handle error
        console.log(error)
        throw error;
      }
    },
  );

