// redux slice for login screen
import { createSlice } from '@reduxjs/toolkit';
import { getListOfNotes, createNote, editNote, getPermissions } from '../apiCalls/note';

const initialState = {
  isNoteListInprogress: false,
  isNoteDeletedInprogress: false,
  isNoteEditedInprogress: false,
  isCreateNoteInprogress: false,
  isGetPermissionsInprogress: false,
  isNoteDeleted: '',
  isNoteEdited: '',
  permissionsData: [],
  noteListData: [],
  notesData: [],
  notesErrorData: '',
  permissionsErrorData: ''
};

export const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    resetNoteListValidationState: (state) => {
      state.isNoteListInprogress = false;
      state.isCreateNoteInprogress = false;
    },
    resetNoteDeleted: state => {
      state.isNoteDeleted = '';
    },
    resetNoteEdited: state => {
      state.isNoteEdited = '';
    },
    resetNoteCreated: state => {
      state.notesData = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(getListOfNotes.pending, (state) => {
      state.isValidatingNote = true;
      state.isNoteListInprogress = false;
    });
    builder.addCase(getListOfNotes.fulfilled, (state, action) => {
      state.isValidatingNote = true;
      state.isNoteListInprogress = true;
      state.noteListData = action.payload.responseObject;
    });
    builder.addCase(getListOfNotes.rejected, (state) => {
      state.isValidatingNote = false;
      state.noteListData = [];
      state.isNoteListInprogress = false;
    });
    builder.addCase(createNote.pending, (state) => {
      state.isValidatingNote = true;
      state.isCreateNoteInprogress = true;
    });
    builder.addCase(createNote.fulfilled, (state, action) => {
      state.isValidatingNote = false;
      state.isCreateNoteInprogress = false;
      if(action.payload.status === 'OK') {
        state.notesData = action.payload.responseObject;
      } else {
        state.notesErrorData = 'something went wrong please try again';
      }
      
      //state.notesData = action.payload;
    });
    builder.addCase(createNote.rejected, (state, action) => {
      state.isCreateNoteInprogress = false;
      state.notesErrorData = action.payload;
    });
    builder.addCase(editNote.pending, (state) => {
      state.isNoteEditedInprogress = false;
    });
    builder.addCase(editNote.fulfilled, (state, action) => {
      state.isNoteEditedInprogress = true;
      if(action.payload === 200) {
        state.isNoteEdited = 'Note Edited Successfully';
      } else {
        state.isNoteEdited = 'something went wrong please try again';
      }
      
    });
    builder.addCase(editNote.rejected, (state) => {
      state.isNoteEditedInprogress = false;
      state.isNoteEdited = 'something went wrong please try again';
    });
    builder.addCase(getPermissions.pending, (state) => {
      state.isGetPermissionsInprogress = true;
    });
    builder.addCase(getPermissions.fulfilled, (state, action) => {
      state.isGetPermissionsInprogress = false;
      if(action.payload) {
        state.permissionsData = action.payload;
      } else {
        state.permissionsErrorData = 'something went wrong please try again';
      }
      
      //state.notesData = action.payload;
    });
    builder.addCase(getPermissions.rejected, (state, action) => {
      state.isGetPermissionsInprogress = false;
      state.permissionsErrorData = action.payload;
    });

  },
});

export const { resetNoteListValidationState, resetNoteDeleted, resetNoteCreated, resetNoteEdited } = notesSlice.actions;

export default notesSlice.reducer;
