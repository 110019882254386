// redux slice for login screen
import {createSlice} from '@reduxjs/toolkit';
import {getHomeIcon, getHomeBanner, updateHomeBanner, postHomeBanner,getLoginBanner,postLoginBanner,updateLoginBanner} from '../apiCalls/banner';

const initialState = {
  isIconsreceived: false,
  isIconsError: false,
  homeBannerIcons: [],
  isGetBannerLoading: false,
  isGetBannerError: false,
  homeBannerInfo: [],
  isBannerLoading: false,
  isbannerUpdated: false,
  isCreateHomeBanner: false,

  isGetLoginBannerLoading:false,
  isGetLoginBannerError:false,
  loginBannerInfo:[],
  isCreateLoginBanner:false,
  isLoginBannerLoading:false,
  isLoginBannerUpdated:false
};

export const bannerSlice = createSlice({
  name: 'bannerInfo',
  initialState,
  reducers: {
    resetBannerdata: (state) => {
      state.isBannerLoading = false;
      state.isbannerUpdated = false;
      state.isCreateHomeBanner = false;
      state.isCreateLoginBanner= false;
      state.isLoginBannerUpdated=false;
      state.isLoginBannerLoading=false;
    },
    resetIsBannerEdited:state=>
    {
      state.isbannerUpdated='';
      state.isCreateHomeBanner=false;
    },
    resetIsLoginBannerEdited:state=>
    {
      state.isLoginBannerUpdated='';
      state.isCreateLoginBanner=false;
    }
  },
  extraReducers: builder => {
    builder.addCase(getHomeIcon.pending, (state) => {
      state.isIconsreceived = false;
      state.isLoggedIn = false;
      state.homeBannerIcons = [];
    });
    builder.addCase(getHomeIcon.fulfilled, (state, action) => {
      state.isIconsreceived = true;
      state.isIconsError = false;
      state.homeBannerIcons = action.payload;
    
    });
    builder.addCase(getHomeIcon.rejected, (state) => {
      state.isIconsreceived = false;
      state.isIconsError = true;
      state.homeBannerIcons = [];
    });
    builder.addCase(getHomeBanner.pending, (state) => {
      state.isGetBannerLoading = false;
      state.isLoggedIn = false;
      state.homeBannerInfo = [];
    });
    builder.addCase(getHomeBanner.fulfilled, (state, action) => {
      state.isGetBannerLoading = true;
      state.isGetBannerError = false;
      state.homeBannerInfo = action.payload.responseObject;
    
    });
    builder.addCase(getHomeBanner.rejected, (state) => {
     state.isGetBannerLoading = false;
      state.isGetBannerError = true;
      state.homeBannerInfo = [];
    });
    builder.addCase(updateHomeBanner.pending, (state) => {
      state.isBannerLoading = false;
      state.isbannerUpdated = false;
    });
    builder.addCase(updateHomeBanner.fulfilled, (state, action) => {
      if(action.payload === 200) {
        state.isBannerLoading = true;
        state.isbannerUpdated = 'Home Banner Updated Successfully';
      } else {
        state.isBannerLoading = false;
        state.isbannerUpdated = 'Something went wrong please try again';
      }  
    });
    builder.addCase(updateHomeBanner.rejected, (state) => {
      state.isBannerLoading = false;
      state.isbannerUpdated = 'Something went wrong please try again';
    });
    
    builder.addCase(postHomeBanner.pending, (state) => {
      state.isCreateHomeBanner = false;
    });
    builder.addCase(postHomeBanner.fulfilled, (state, action) => {
      if(action.payload === 201) {
        state.isCreateHomeBanner = true;
      } else {
        state.isCreateHomeBanner = false;
      }  

    });
    builder.addCase(postHomeBanner.rejected, (state) => {
      state.isCreateHomeBanner = false;
    });

    //Login Banner

    builder.addCase(getLoginBanner.pending, (state) => {
      state.isGetLoginBannerLoading = false;
      state.isLoggedIn = false;
      state.loginBannerInfo = [];
    });
    builder.addCase(getLoginBanner.fulfilled, (state, action) => {
      state.isGetLoginBannerLoading = true;
      state.isGetLoginBannerError = false;
      state.loginBannerInfo = action.payload.responseObject;
    
    });
    builder.addCase(getLoginBanner.rejected, (state) => {
      state.isGetLoginBannerLoading = false;
      state.isGetLoginBannerError = true;
      state.loginBannerInfo = [];
    });

    //Add Login Banner
    builder.addCase(postLoginBanner.pending, (state) => {
      state.isCreateLoginBanner = false;
    });
    builder.addCase(postLoginBanner.fulfilled, (state, action) => {
      if(action.payload === 201) {
        state.isCreateLoginBanner = true;
      } else {
        state.isCreateLoginBanner = false;
      }  
    });
    builder.addCase(postLoginBanner.rejected, (state) => {
      state.isCreateLoginBanner = false;
    });

    //Update Login Banner
    builder.addCase(updateLoginBanner.pending, (state) => {
      state.isLoginBannerLoading = false;
      state.isLoginBannerUpdated = false;
    });
    builder.addCase(updateLoginBanner.fulfilled, (state, action) => {
      if(action.payload === 200) {
        state.isLoginBannerLoading = true;
        state.isLoginBannerUpdated = 'Login Banner Updated Successfully';
      } else {
        state.isLoginBannerLoading = false;
        state.isLoginBannerUpdated = 'Something went wrong please try again';
      }  
    });
    builder.addCase(updateLoginBanner.rejected, (state) => {
      state.isLoginBannerLoading = false;
      state.isLoginBannerUpdated = 'Something went wrong please try again';
    });
  },
});

export const {resetBannerdata,resetIsBannerEdited,resetIsLoginBannerEdited } = bannerSlice.actions;

export default bannerSlice.reducer;
