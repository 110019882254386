// redux slice for login screen
import { createSlice } from '@reduxjs/toolkit';
import { getListOfUsers, createUser, deleteUser, editUser } from '../apiCalls/users';

const initialState = {
  isUserListInprogress: false,
  isUserDeletedInprogress: false,
  isUserEditedInprogress: false,
  isCreateUserInprogress: false,
  userStatusCode: 0,
  isUserDeleted: '',
  isUserEdited: '',
  userListData: [],
  usersData: [],
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUserListValidationState: (state) => {
      state.isUserListInprogress = false;
      state.isCreateUserInprogress = false;
    },
    resetUserDeleted: state => {
      state.isUserDeleted = '';
    },
    resetUserEdited: state => {
      state.isUserEdited = '';
    },
  },
  extraReducers: builder => {
    builder.addCase(getListOfUsers.pending, (state) => {
      state.isValidatingUser = true;
      state.isUserListInprogress = false;
    });
    builder.addCase(getListOfUsers.fulfilled, (state, action) => {
      state.isValidatingUser = true;
      state.isUserListInprogress = true;
      state.userStatusCode = action.payload.statusCode
      state.isUserDeletedInprogress = true;
      if(action.payload.statusCode === 204) {
        state.isUserDeleted = 'User Deleted Successfully';
      } else if(action.payload.statusCode === 201) {
        state.isUserEdited = 'User Edited Successfully';
      } else if(action.payload.statusCode !== 0){
        state.isUserDeleted = 'Something went wrong please try again';
      }
      state.userListData = action.payload.listData && action.payload.listData.userList;
    });
    builder.addCase(getListOfUsers.rejected, (state) => {
      state.isValidatingUser = false;
      state.userListData = [];
      state.isUserListInprogress = false;
    });
    builder.addCase(createUser.pending, (state) => {
      state.isValidatingUser = true;
      state.isCreateUserInprogress = false;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.isValidatingUser = true;
      state.isCreateUserInprogress = true;
      state.usersData = action.payload;
      //state.usersData = action.payload;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.isCreateUserInprogress = false;
      state.usersData = action.payload;
    });
    builder.addCase(deleteUser.pending, (state) => {
      state.isUserDeletedInprogress = false;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.isUserDeletedInprogress = true;
      if(action.payload === 204) {
        state.isUserDeleted = 'User Deleted Successfully';
      } else {
        state.isUserDeleted = 'Something went wrong please try again';
      }
      
    });
    builder.addCase(deleteUser.rejected, (state) => {
      state.isUserDeletedInprogress = false;
      state.isUserDeleted = 'Something went wrong please try again';
    });
    builder.addCase(editUser.pending, (state) => {
      state.isUserEditedInprogress = false;
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      state.isUserEditedInprogress = true;
      if(action.payload === 201) {
        state.isUserEdited = 'User Edited Successfully';
      } else {
        state.isUserEdited = 'Something went wrong please try again';
      }
      
    });
    builder.addCase(editUser.rejected, (state) => {
      state.isUserEditedInprogress = false;
      state.isUserEdited = 'Something went wrong please try again';
    });

  },
});

export const { resetUserListValidationState, resetUserDeleted, resetUserEdited } = usersSlice.actions;

export default usersSlice.reducer;
