import {createAsyncThunk} from '@reduxjs/toolkit';
import { API_BASE_URL } from "../../environment";
import oktaAuth from "../../oktaAuth";


// API call for get list notes data
export const getListOfNotes = createAsyncThunk(
  'getListOfNotes',
  async () => {
    const url = `${API_BASE_URL}api/v1/notes/allNoteDetails`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
          Accept: 'application/json',
          'X-Frame-Options': 'SAMEORIGIN',
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
       
        // Handle non-2xx response
        throw new Error('Request failed with status ' + response.status);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      console.log(error)
      throw error;
    }
  },
);


// API call for edit user data
export const editNote = createAsyncThunk(
  'editNote',
  async ({updatedData}, { dispatch }) => {
    const url = `${API_BASE_URL}api/v1/notes/updateNote`;
    try {
      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(updatedData),
        headers: {
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
          Accept: 'application/json',
          'X-Frame-Options': 'SAMEORIGIN',
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        dispatch(getListOfNotes())
      }
      return response.status;
    } catch (error) {
      // Handle error
      console.log(error)
      throw error;
    }
  },
);

// API call for create notes data
export const createNote = createAsyncThunk(
    'createNote',
    async (formData) => {
      const url = `${API_BASE_URL}api/v1/notes/note`;
      try {
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(formData),
          headers: {
            Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
            Accept: 'application/json',
            'X-Frame-Options': 'SAMEORIGIN',
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        // Handle error
        console.log(error)
        throw error;
      }
    },
  );

  // API call for get all permissions data
export const getPermissions = createAsyncThunk(
  'getPermissions',
  async () => {
    const url = `${API_BASE_URL}api/v1/users/stories`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
          Accept: 'application/json',
          'X-Frame-Options': 'SAMEORIGIN',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      console.log(error)
      throw error;
    }
  },
);

