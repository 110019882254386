import {createAsyncThunk} from '@reduxjs/toolkit';
import { API_BASE_URL } from "../../environment";

// API call for dashboard data
export const validateUser = createAsyncThunk(
  'validateUser',
  async (params) => {
    const url = `${API_BASE_URL}api/v1/users/login/`;
    try {
      const response = await fetch(url, {
        method: 'POST',        
        headers: {
        Accept: 'application/json',
        'X-Frame-Options': 'SAMEORIGIN',
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(params)
      });
      const data = await response.json();
     
      return data;
    } catch (error) {
      // Handle error
      console.log(error)
      throw error;
    }
  },
);

