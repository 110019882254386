import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_BASE_URL } from "../../environment";
import { useNavigate } from 'react-router-dom';
import oktaAuth from "../../oktaAuth";

// navigationActions.js
export const navigateToScreen = (screenName) => {
  const navigate = useNavigate();
  navigate(`/${screenName}`);
};

// API call for dashboard data
export const getHomeIcon = createAsyncThunk(
  'homeIcon',
  async () => {
    const url = `${API_BASE_URL}api/imageinfo/all`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
          Accept: 'application/json',
          'X-Frame-Options': 'SAMEORIGIN',
          'Content-Type': 'application/json',
        },
      });

      // if (!response.ok) {
      //   console.log("response",response);
      //   // Handle non-2xx response
      //   throw new Error('Request failed with status ' + response.status);
      // }
      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      console.log(error)
      throw error;
    }
  },
);

//API call for homebanner data
export const postHomeBanner = createAsyncThunk(
  'createbanner',
  async (formData) => {
    const url = `${API_BASE_URL}api/v1/banners/homeBanners`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
          Accept: 'application/json',
          'X-Frame-Options': 'SAMEORIGIN',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
      return response.status;
    } catch (error) {
      // Handle error
      console.log(error)
      throw error;
    }
  },
);

//API call for homebanner data
export const updateHomeBanner = createAsyncThunk(
  'updatebanner',
  async (formData, { dispatch }) => {
    const url = `${API_BASE_URL}api/v1/banners/homeBanner`;
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
          Accept: 'application/json',
          'X-Frame-Options': 'SAMEORIGIN',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
      if (response.status === 200) {
        dispatch(getHomeBanner())
        return response.status;
      }
    } catch (error) {
      // Handle error
      console.log(error)
      throw error;
    }
  },
);

//API call for getting home banner data
export const getHomeBanner = createAsyncThunk(
  'getHomeBanner',
  async () => {
    const url = `${API_BASE_URL}api/v1/banners/homeBannerDetails`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
          Accept: 'application/json',
          'X-Frame-Options': 'SAMEORIGIN',
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        // Handle non-2xx response
        throw new Error('Request failed with status ' + response.status);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      console.log(error)
      throw error;
    }
  },
);

//API call for getting Login banner data
export const getLoginBanner = createAsyncThunk(
  'getLoginBanner',
  async () => {
    const url = `${API_BASE_URL}api/v1/banners/loginBannerDetails`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          //    Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
          Accept: 'application/json',
          'X-Frame-Options': 'SAMEORIGIN',
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        // Handle non-2xx response
        throw new Error('Request failed with status ' + response.status);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      console.log(error)
      throw error;
    }
  },
);

//API call for loginbanner data
export const postLoginBanner = createAsyncThunk(
  'postLoginBanner',
  async (formData) => {
    const url = `${API_BASE_URL}api/v1/banners/loginBanners`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
          Accept: 'application/json',
          'X-Frame-Options': 'SAMEORIGIN',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
      return response.status;
    } catch (error) {
      // Handle error
      console.log(error)
      throw error;
    }
  },
);

//API call for UpdateLoginbanner data
export const updateLoginBanner = createAsyncThunk(
  'updateLoginBanner',
  async (formData, { dispatch }) => {
    const url = `${API_BASE_URL}api/v1/banners/loginBanner`;
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
          Accept: 'application/json',
          'X-Frame-Options': 'SAMEORIGIN',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
      if (response.status === 200) {
        dispatch(getLoginBanner())
        return response.status;
      }
    } catch (error) {
      // Handle error
      console.log(error)
      throw error;
    }
  },
);

